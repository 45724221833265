import { Listeners } from "../../enumerations/web";
import { postAjaxJSON } from "../../utils/ajax";

const DEFAULT_SIGN_IN_STATUS = "sign in";
const DEFAULT_GUEST_SIGN_IN_STATUS = "continue as guest";
const SIGNING_IN_STATUS = "signing into board...";
const GUEST_SIGNING_IN_STATUS = "continuing as guest...";

export class LoginForm {
	private loginControl: HTMLFormElement;
	private loginButton: HTMLButtonElement | null = null;
	private loginButtonError: HTMLSpanElement | null = null;
	private guestButton: HTMLButtonElement | null = null;
	private email: HTMLInputElement | null = null;
	private password: HTMLInputElement | null = null;
	private emailInputError: HTMLSpanElement | null = null;
	private passwordInputError: HTMLSpanElement | null = null;
	private validEmail: boolean;
	private validPassword: boolean;
	private emailTouched: boolean = false;

	public constructor(form: HTMLFormElement) {
		if (!form) {
			throw new Error("Could not find login form");
		}

		this.loginControl = form;

		const emailContainer = this.loginControl.querySelector<HTMLInputElement>("#sign-in-email");
		const passwordContainer = this.loginControl.querySelector<HTMLInputElement>("#sign-in-password");
		const submitContainer = this.loginControl.querySelector<HTMLInputElement>("#sign-in-submit-container");

		if (emailContainer) {
			this.email = emailContainer.querySelector<HTMLInputElement>("input");
			this.emailInputError = emailContainer.querySelector<HTMLSpanElement>(".input-error");
		}

		if (passwordContainer) {
			this.passwordInputError = passwordContainer.querySelector<HTMLSpanElement>(".input-error");
			this.password = passwordContainer.querySelector<HTMLInputElement>("input");
		}

		if (submitContainer) {
			this.loginButton = submitContainer.querySelector<HTMLButtonElement>("#sign-in-button");
			this.loginButtonError = submitContainer.querySelector<HTMLButtonElement>(".input-error");
		}

		this.guestButton = this.loginControl.querySelector<HTMLButtonElement>("#sign-in-guest-button");

		this.validEmail = false;
		this.validPassword = false;

		this.listenForLogin();
	}

	public show() {
		this.loginControl.classList.add("visible");
	}

	public setButtonText(button: HTMLButtonElement, message: string) {
		button.innerText = message;
	}

	private listenForLogin() {
		if (!this.email) {
			throw new Error("Could not find email field");
		}

		if (!this.password) {
			throw new Error("Could not find password field");
		}

		this.listenForEmail();
		this.listenForPassword();

		this.loginControl.addEventListener(Listeners.SUBMIT_EVENT, (event) => {
			event.preventDefault();

			if (this.email && this.password) {
				this.validateEmail(this.email.value);
				this.validatePassword(this.password.value);

				if (this.validEmail && this.validPassword) {
					if (this.loginButton) {
						this.setButtonText(this.loginButton, SIGNING_IN_STATUS);
					}
					this.loginWithCredentials(this.email.value, this.password.value);
				}
			}
		});

		if (this.guestButton) {
			this.guestButton.addEventListener(Listeners.CLICK_EVENT, (event) => {
				event.preventDefault();
				if (this.guestButton) {
					this.setButtonText(this.guestButton, GUEST_SIGNING_IN_STATUS);
				}
				this.loginWithCredentials("guest", "");
				event.stopPropagation();
			});
		}
	}

	private listenForEmail() {
		const checkEmail = () => {
			if (this.emailTouched && this.email) {
				this.validateEmail(this.email.value);
			}
		};

		if (this.email) {
			this.email.addEventListener(Listeners.BLUR_EVENT, () => {
				this.emailTouched = true;
				checkEmail();
			});

			this.email.addEventListener(Listeners.INPUT_EVENT, () => {
				checkEmail();
				this.toggleError(this.loginButtonError, false);
				// HACK - to enable submit button after fields autofill
				setTimeout(() => {
					if (this.password && this.password.value && this.email && this.email.value) {
						this.validateEmail(this.email.value);
						this.validatePassword(this.password.value);
					}
				});
			});
		}
	}

	private listenForPassword() {
		if (this.password) {
			this.password.addEventListener(Listeners.INPUT_EVENT, () => {
				if (this.password) {
					this.validatePassword(this.password.value);
				}
				this.toggleError(this.loginButtonError, false);
				// HACK - to enable submit button after fields autofill
				if (this.email && this.email.value) {
					this.validateEmail(this.email.value);
				}
			});
		}
	}

	private validateEmail(email: string) {
		const isInvalidEmail = this.isInvalidEmail(email);
		this.validEmail = !isInvalidEmail;
		this.toggleError(this.emailInputError, isInvalidEmail);
		this.toggleLoginButtonDisabled();
	}

	private validatePassword(password: string) {
		const isInvalidPassword = this.inputEmpty(password);
		this.validPassword = !isInvalidPassword;
		this.toggleError(this.passwordInputError, isInvalidPassword);
		this.toggleLoginButtonDisabled();
	}

	private isInvalidEmail(emailString: string) {
		// tslint:disable-next-line:max-line-length
		return !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
			emailString
		);
	}

	private inputEmpty(value: string) {
		return /^\s*$/.test(value);
	}

	private toggleError(errorEl: HTMLSpanElement | null, show: boolean) {
		if (!errorEl) {
			return;
		}
		errorEl.classList.toggle("visible", show);
	}

	private toggleLoginButtonDisabled(value?: boolean) {
		if (this.loginButton) {
			this.loginButton.disabled = typeof value === "undefined" ? !this.validEmail || !this.validPassword : value;
		}
	}

	private async loginWithCredentials(email: string, pass: string) {
		try {
			this.toggleLoginButtonDisabled(true);
			await postAjaxJSON("/auth/creds-login", {
				email,
				pass
			});

			window.location.href = "/portfolio";
		} catch (err) {
			if (this.loginButton) {
				this.setButtonText(this.loginButton, DEFAULT_SIGN_IN_STATUS);
				this.toggleError(this.loginButtonError, true);
			}
			if (this.guestButton) {
				this.setButtonText(this.guestButton, DEFAULT_GUEST_SIGN_IN_STATUS);
			}
		} finally {
			this.toggleLoginButtonDisabled();
		}
	}
}
