import { OK } from "http-status-codes";
import { Listeners, XHR } from "../enumerations/web";

async function setupJSONHandlers<T>(url: string, xhr: XMLHttpRequest): Promise<T> {
	return await new Promise<T>((resolve, reject) => {
		xhr.addEventListener(Listeners.LOAD_EVENT, () => {
			const response = JSON.parse(xhr.responseText);

			if (xhr.status === OK && response.result) {
				const result: T = response.result as T;

				resolve(result);
			} else {
				// tslint:disable-next-line:no-console
				console.error(response.error);
				reject(new Error(response.error));
			}
		});
		xhr.addEventListener(Listeners.ABORT_EVENT, (e) => {
			const msg = `URL "${url}" aborted`;
			// tslint:disable-next-line:no-console
			console.error(msg);
			reject(new Error(msg));
		});
		xhr.addEventListener(Listeners.ERROR_EVENT, (e) => {
			const msg = `URL "${url}" error`;
			// tslint:disable-next-line:no-console
			console.error(msg);
			reject(new Error(msg));
		});
	});
}

export async function getAjaxJSON<T>(url: string): Promise<T> {
	const xhr = new XMLHttpRequest();
	xhr.open("GET", url);
	xhr.send();
	return setupJSONHandlers(url, xhr);
}

export async function postAjaxJSON<T>(url: string, data: any): Promise<T> {
	const xhr = new XMLHttpRequest();
	xhr.open("POST", url);
	xhr.setRequestHeader(XHR.CONTENT_TYPE_HEADER, XHR.APPLICATION_JSON_CONTENT_TYPE);
	xhr.send(JSON.stringify(data));

	return setupJSONHandlers(url, xhr);
}

export async function postAjaxForm<T>(url: string, data: FormData): Promise<T> {
	const xhr = new XMLHttpRequest();
	xhr.open("POST", url);
	xhr.send(data);

	return setupJSONHandlers(url, xhr);
}

export async function wait(ms: number): Promise<void> {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve();
		}, ms);
	});
}
