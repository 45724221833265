export enum XHR {
	CONTENT_TYPE_HEADER = "Content-Type",
	CONTENT_LENGTH_HEADER = "Content-Length",
	RESPONSE_ARRAY_BUFFER = "arraybuffer",
	APPLICATION_FORM_ENCODE_CONTENT_TYPE = "application/x-www-form-urlencoded",
	APPLICATION_JSON_CONTENT_TYPE = "application/json",
	APPLICATION_GRAPHQL_CONTENT_TYPE = "application/graphql",
	APPLICATION_PDF_CONTENT_TYPE = "application/pdf",
	MULTIPART_FORM_CONTENT_TYPE = "multipart/form-data",
	TEXT_CSS = "text/css"
}

export enum Listeners {
	LOAD_EVENT = "load",
	LOAD_END_EVENT = "loadend",
	TIMEOUT_EVENT = "timeout",
	ERROR_EVENT = "error",
	ABORT_EVENT = "abort",
	CLICK_EVENT = "click",
	SELECT_EVENT = "select",
	SUBMIT_EVENT = "submit",
	INPUT_EVENT = "input",
	SCROLL_EVENT = "scroll",
	CHANGE_EVENT = "change",
	MOUSE_DOWN_EVENT = "mousedown",
	MOUSE_UP_EVENT = "mouseup",
	MOUSE_MOVE_EVENT = "mousemove",
	MOUSE_WHEEL_EVENT = "mousewheel",
	TOUCH_START_EVENT = "touchstart",
	TOUCH_END_EVENT = "touchend",
	TOUCH_MOVE_EVENT = "touchmove",
	RESIZE_EVENT = "resize",
	UNLOAD_EVENT = "unload",
	BEFORE_UNLOAD_EVENT = "beforeunload",
	KEY_DOWN_EVENT = "keydown",
	KEY_UP_EVENT = "keyup",
	DRAG_START_EVENT = "dragstart",
	DRAG_END_EVENT = "dragend",
	DRAG_MOVE_EVENT = "drag",
	DRAG_ENTER_EVENT = "dragenter",
	DRAG_LEAVE_EVENT = "dragleave",
	DRAG_OVER_EVENT = "dragover",
	DRAG_DROP_EVENT = "drop",
	BLUR_EVENT = "blur"
}

export enum ReadyState {
	LOADING = "loading",
	INTERACTIVE = "interactive",
	COMPLETE = "complete"
}

export enum DataElements {
	CUSTOMER_ID_SELECTOR = "#data-customerId",
	BOARD_ID_SELECTOR = "#data-boardId",
	BOARD_NAME_SELECTOR = "#data-boardName",
	ICON_PATH_SELECTOR = "#data-iconPath",
	IS_NATIVE_SELECTOR = "#data-isNative",
	IS_GUEST_SELECTOR = "#data-isGuest",
	ENSEMBLE_URL_SELECTOR = "#data-ensemble-url"
}

export enum ButtonClasses {
	PRIMARY_DEFAULT = "owPrimaryDefaultButton",
	PRIMARY_LIGHT_GREY = "owPrimaryLightGreyButton",

	PANEL_DEFAULT = "owPanelDefaultButton",
	PANEL_LIGHT_GREY = "owPanelLightGreyButton",

	SECONDARY_DEFAULT = "owSecondaryDefaultButton",
	SECONDARY_LIGHT_GREY = "owSecondaryLightGreyButton",

	MODAL_DEFAULT = "owModalDefaultButton",
	MODAL_LIGHT_GREY = "owModalLightGreyButton"
}

export enum COLORS {
	BLACK = "var(--black)",
	GRAY = "var(--gray)",
	MED_GRAY = "var(--med-gray)",
	DARK_GRAY = "var(--dark-gray)",
	LIGHT_GRAY = "var(--light-gray)",
	WHITE = "var(--white)",
	BACKGROUND = "var(--background)",
	OVERLAY = "var(--overlay)",
	PRIMARY = "var(--primary)",
	PANEL = "var(--panel)",
	WARNING = "var(--warning)"
}
