import { Listeners } from "../enumerations/web";
import { LoginForm } from "./controls/login";

window.addEventListener(Listeners.LOAD_EVENT, () => {
	const config = (window as any).config;

	const form = document.querySelector("#sign-in") as HTMLFormElement;
	if (form) {
		const loginForm = new LoginForm(form);

		if (config && !config.simpleLogin) {
			listenForSignInAgain(loginForm);
		}
	}
});

function listenForSignInAgain(loginForm: LoginForm) {
	const loggedOutWrapper = (document.querySelector(".logged-out-wrapper") ||
		document.querySelector(".logout-control")) as HTMLElement;
	const signInAgainButton = document.querySelector(".sign-in-again") as HTMLButtonElement;
	const blurredBackground = document.querySelector(".splash-background.blurred") as HTMLElement;

	if (signInAgainButton) {
		signInAgainButton.addEventListener(Listeners.CLICK_EVENT, () => {
			loggedOutWrapper.classList.remove("visible");
			if (blurredBackground) {
				blurredBackground.classList.add("visible");
			}
			loginForm.show();
		});
	}
}
